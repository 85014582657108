import revive_payload_client_SuyEB3gFRA from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3vgEdulZpQ from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_RDdr74KMpg from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_67rMiTRwUk from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt-site-config@2.2.16_rollup@4.24.4_vite@5.4.10_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_EY9xl6d621 from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_H1VvW9c7LV from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SdtZs3CWM2 from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_FamgN4w63z from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_nPAqWyOscF from "/usr/src/nuxt-app/node_modules/.pnpm/@pinia+nuxt@0.4.7_rollup@4.24.4_typescript@5.0.2_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_miQ0KLt65R from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_8VeZ2cSX0F from "/usr/src/nuxt-app/node_modules/.pnpm/@sentry+nuxt@8.34.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_wj6obta7jxtctky2xf5nbpjwyq/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/usr/src/nuxt-app/.nuxt/sentry-client-config.mjs";
import init_grdyAU8lhf from "/usr/src/nuxt-app/plugins/init.ts";
import install_device_tags_script_client_ytPCaoqvVJ from "/usr/src/nuxt-app/plugins/install-device-tags-script.client.ts";
import snowplowTracking_client_5JoiVSpIcm from "/usr/src/nuxt-app/plugins/snowplowTracking.client.ts";
import vue_social_sharing_ZALq4hYFAx from "/usr/src/nuxt-app/plugins/vue-social-sharing.ts";
export default [
  revive_payload_client_SuyEB3gFRA,
  unhead_3vgEdulZpQ,
  router_RDdr74KMpg,
  _0_siteConfig_67rMiTRwUk,
  payload_client_EY9xl6d621,
  navigation_repaint_client_H1VvW9c7LV,
  check_outdated_build_client_SdtZs3CWM2,
  chunk_reload_client_FamgN4w63z,
  plugin_vue3_nPAqWyOscF,
  components_plugin_KR1HBZs4kY,
  prefetch_client_miQ0KLt65R,
  sentry_client_8VeZ2cSX0F,
  sentry_client_config_o34nk2sJbg,
  init_grdyAU8lhf,
  install_device_tags_script_client_ytPCaoqvVJ,
  snowplowTracking_client_5JoiVSpIcm,
  vue_social_sharing_ZALq4hYFAx
]