import { useAuthStore } from '~/stores/auth'

export default defineNuxtPlugin(() => {
	const {
		getAuthCookies,
	} = useCookieManager()

	const authCookies = getAuthCookies()
	const { setDeviceId } = useAuthStore()

	const config = useRuntimeConfig()
	const isProd = config.public.env === 'prod'
	const API_KEY = config.public.DEVICE_TAGS_API_KEY
	const CLIENT_ID = config.public.clientId
	const SCRIPT_URL = 'https://icdt.incrowdsports.com/icdt.js'

	window.icdt = {
		token: authCookies.access_token,
		anonymous: false,
		isDevelopment: !isProd,
	};
	(function (w, d, s, n, u, k, c) {
		w[n] = w[n] || {}
		w[n].apiKey = k
		w[n].clientId = c
		const f = d.getElementsByTagName(s)[0]
		const j = d.createElement(s)
		j.async = 1
		j.src = u
		f.parentNode.insertBefore(j, f)
	})(window, document, 'script', 'icdt', SCRIPT_URL, API_KEY, CLIENT_ID)

	setDeviceId(authCookies.deviceId as string)
})
