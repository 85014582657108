import validate from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45trailing_45slash_45global from "/usr/src/nuxt-app/middleware/01.redirectTrailingSlash.global.ts";
import auth_45global from "/usr/src/nuxt-app/middleware/auth.global.ts";
import manifest_45route_45rule from "/usr/src/nuxt-app/node_modules/.pnpm/nuxt@3.13.1_eslint@8.44.0_rollup@4.24.4_typescript@5.0.2_vite@5.4.10/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45trailing_45slash_45global,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}