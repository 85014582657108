import useTrackingFunctions from '~/composables/trackingFunctions'

export default defineNuxtPlugin(NuxtApp => {
	const {
		initTrackerScripts,
	} = useTrackingFunctions()

	NuxtApp.hook('app:mounted', () => {
		const {
			getUserId,
		} = useCookieManager()

		initTrackerScripts()
		window.snowplow?.('setUserId', getUserId() || '')
		window.snowplow?.('enableActivityTracking', 10, 10)
	})

	NuxtApp.$router.afterEach(() => {
		setTimeout(() => {
			window?.snowplow?.('trackPageView')
		}, 3000)
	})
})
