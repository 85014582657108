import { defineStore, storeToRefs } from 'pinia'
import { authHandler, AuthObj, getPreferences, getProfile } from 'ic-js-auth'
import { getTeamById, Team } from 'ic-js-rugby'

export const useAuthStore = defineStore('auth', () => {
	const config = useRuntimeConfig()
	const authBody = ref<{ client_id: string, deviceId: string | null }>({
		client_id: config.public.clientId,
		deviceId: null,
	})
	const user = ref(null)
	const selectedTeam = ref<Team | null>(null)

	const authObject = ref<AuthObj | null>(null)
	const setDeviceId = (id: string | null) => {
		globalThis.incrowd.authObj = {
			...globalThis.incrowd.authObj,
			deviceId: id,
		}
		authBody.value = {
			...authBody.value,
			deviceId: id,
		}
	}

	const setAuthObject = (value: AuthObj | null) => {
		authObject.value = value
	}

	const setUser = value => {
		user.value = value
	}

	const setSelectedTeam = (value: Team | null) => {
		selectedTeam.value = value
	}

	const refreshToken = async () => {
		const { setAuthObject, fetchUser } = useAuthStore()
		const { setAuthCookies } = useCookieManager()
		const { authBody } = storeToRefs(useAuthStore())

		const body = {
			...authBody.value,
			refresh_token: globalThis.incrowd.authObj.refresh_token,
		}
		const json = await authHandler(body, 'refresh_token')
		if (!json) return
		setAuthObject(json)
		if (json?.access_token && json?.refresh_token) {
			await setAuthCookies(json.access_token, json.refresh_token)
			await fetchUser()
		}
	}
	const signOut = () => {
		try {
			const { removeAuthCookies } = useCookieManager()
			setUser(null)
			setAuthObject(null)
			removeAuthCookies()
		} catch (error) {
			return false
		}
	}
	const fetchUser = async () => {
		const response = await getProfile()
		if (response) {
			setUser(response)
		}
	}

	const fetchSelectedTeam = async () => {
		const favoriteTeamPreferenceKey = config.public.preferencesKeys.favouriteTeam
		const preferences = await getPreferences()
		if (preferences) {
			const favoriteTeam = preferences
				?.find(preference => preference.key === favoriteTeamPreferenceKey)
				?.options
				?.find(option => option.selected)
			if (favoriteTeam) {
				const params = {
					clientId: config.public.clientId,
					provider: config.public.dataProvider,
					competitionId: config.public.compId,
				}
				const response = await getTeamById(favoriteTeam?.metadata?.rugbyVizId, { params })
				const team = response?.data?.[0] || null
				if (team) {
					setSelectedTeam(team)
				}
			}
		}
	}

	return {
		authBody,
		authObject,
		user,
		selectedTeam,
		setDeviceId,
		setAuthObject,
		refreshToken,
		signOut,
		fetchUser,
		fetchSelectedTeam,
	}
})
