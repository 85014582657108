/* eslint-disable camelcase */

import jwt_decode from 'jwt-decode'
import { authHandler } from 'ic-js-auth'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'

const {
	setAuthCookies,
	getAuthCookies,
} = useCookieManager()

export default defineNuxtRouteMiddleware(async (to: any) => {
	if (process.server) return
	const config = useRuntimeConfig()
	const { setAuthObject, refreshToken, signOut, fetchUser, fetchSelectedTeam } = useAuthStore()
	const authStore = useAuthStore()
	const { authBody } = storeToRefs(authStore)
	const { query } = to

	if (query.code && query.state) {
		const host = `${config.public.siteMeta.host}${to.path !== '/' ? to.path : ''}`
		const body = {
			// includes code and state
			...query,
			client_id: config.public.clientId,
			redirect_uri: host,
			device_id: authBody.value?.deviceId,
		}
		const json = await authHandler(body, 'authorization_code')
		if (!json) return navigateTo('/', { replace: true })
		setAuthObject(json)
		if (json?.access_token && json?.refresh_token) {
			await setAuthCookies(json.access_token, json.refresh_token)
			await fetchUser()
			await fetchSelectedTeam()
			window.icdt?.login?.(json.access_token)
		}
		return navigateTo(to.path, { replace: true })
	} else if (query.signout === 'true') {
		window.icdt?.logout?.()
	}

	// Check if tokens needs to be refreshed
	let accessJWT = null
	let refreshJWT = null
	const now = new Date().getTime()

	const authCookies = getAuthCookies()

	try {
		accessJWT = jwt_decode(authCookies.access_token || '')
	} catch (e) {
		console.log('No Access Token')
	}
	try {
		refreshJWT = jwt_decode(authCookies.refresh_token || '')
	} catch (e) {
		console.log('No Refresh Token')
	}

	if (accessJWT) {
		const aTokenExpireDate = accessJWT.exp * 1000
		if (aTokenExpireDate <= now) {
			if (refreshJWT) {
				const rTokenExpireDate = refreshJWT.exp * 1000
				if (rTokenExpireDate <= now) {
					signOut()
				} else refreshToken()
			} else signOut()
		} else {
			fetchUser()
			fetchSelectedTeam()
		}
	} else if (refreshJWT) {
		const rTokenExpireDate = refreshJWT.exp * 1000
		if (rTokenExpireDate <= now) {
			signOut()
		} else refreshToken()
	} else signOut()
})
