// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

export default function useCookieManager () {
	const getAuthCookies = () => {
		const accessToken = useCookie(getAccessTokenCookieName())
		const refreshToken = useCookie(getRefreshTokenCookieName())
		const deviceId = useCookie('incrowd_device_id')

		globalThis.incrowd.authObj = {
			...globalThis.incrowd.authObj,
			access_token: accessToken.value,
			refresh_token: refreshToken.value,
			deviceId: deviceId.value,
		}

		return {
			access_token: accessToken.value,
			refresh_token: refreshToken.value,
			deviceId: deviceId.value,
		}
	}
	const getAccessTokenCookieName = () => {
		const { clientId, env } = globalThis.incrowd

		return env === 'stage' ? `${clientId}_sso_at_stage` : `${clientId}_sso_at`
	}

	const getRefreshTokenCookieName = () => {
		const { clientId, env } = globalThis.incrowd
		return env === 'stage' ? `${clientId}_sso_rt_stage` : `${clientId}_sso_rt`
	}

	const setAuthCookies = async (accessToken: string, refreshToken: string) => {
		let at = null
		let rt = null
		try { at = await jwt_decode(accessToken) } catch (e) { console.log('No Access Token') }
		try { rt = await jwt_decode(refreshToken) } catch (e) { console.log('No Refresh Token') }
		globalThis.incrowd.authObj = {
			access_token: '',
			refresh_token: '',
		}
		if (at) {
			let accessTokenCookie = useCookie(getAccessTokenCookieName(), {
				path: '/',
				domain: getHostname(),
				secure: true,
				sameSite: 'lax',
				expires: new Date(new Date(at.exp * 1000)),
			})

			accessTokenCookie.value = accessToken
			globalThis.incrowd.authObj.access_token = accessToken
		}
		if (rt) {
			let refreshTokenCookie = useCookie(getRefreshTokenCookieName(), {
				path: '/',
				domain: getHostname(),
				secure: true,
				sameSite: 'lax',
				expires: new Date(new Date(rt.exp * 1000)),
			})

			refreshTokenCookie.value = refreshToken
			globalThis.incrowd.authObj.refresh_token = refreshToken
		}
	}

	const getHostname = () => {
		return extractTopLevelDomain(window.location.hostname) || window.location.hostname
	}

	const extractTopLevelDomain = url => {
		const domainArr = url.split('.')
		const count = domainArr.length

		if (url.endsWith('co.uk')) {
			return `${domainArr[count - 3]}.${domainArr[count - 2]}.${domainArr[count - 1]}`
		} else if (count >= 2) return `${domainArr[count - 2]}.${domainArr[count - 1]}`

		return false
	}

	const removeAuthCookies = () => {
		let accessTokenCookie = useCookie(getAccessTokenCookieName(), {
			path: '/',
			domain: getHostname(),
		})
		let refreshTokenCookie = useCookie(getRefreshTokenCookieName(), {
			path: '/',
			domain: getHostname(),
		})
		accessTokenCookie.value = null
		refreshTokenCookie.value = null
	}

	const getUserId = () => {
		const token = globalThis.incrowd.authObj.access_token
		const user = token ? jwt_decode(token) : null
		return user?.sub
	}

	return {
		setAuthCookies,
		getAuthCookies,
		removeAuthCookies,
		getUserId,
	}
}
