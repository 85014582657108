import { initGtmTrackers, initSnowplowTrackers, trackPageChange } from 'ic-js-track'

export default function useTrackingFunctions () {
	const initGa4 = (track: boolean) => {
		initGtmTrackers(track, globalThis.incrowd.gtmId)
	}

	const initSnowplow = () => {
		// the true flag indicates we want to disable anonymous tracking
		// we might wan't to control this differently in the future
		initSnowplowTrackers(true, {
			appId: globalThis.incrowd.snowplow.appId,
			trackingUrl: globalThis.incrowd.snowplow.trackingUrl,
			snowplowTrackingId: globalThis.incrowd.snowplow.SNOWPLOW_TRACKING_ID,
		})
	}

	const initTrackerScripts = () => {
		initGa4(true)
		initSnowplow()
	}

	return {
		initTrackerScripts,
		trackPageChange,
	}
}
